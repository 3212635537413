<template>
  <div class="itemScroll_container" ref="itemScrollList" id="itemScroll">
    <div class="itemScroll_content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import BScroll from "better-scroll";
export default {
  name: "itemScroll",
  props: {
    isTop: {
      type: Boolean,
      default: false,
    },
    isBottom: {
      type: Boolean,
      default: false,
    },
    isClearMove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bounce_time: 600,
      isTempTop: false,
    };
  },
  beforeUnmount() {
    document
      .getElementById("itemScroll")
      .removeEventListener("touchmove", this.$tools.noScroll, {
        passive: false,
      });
    // this.$tools.removeTouch()
  },
  created() {
    if (this.isClearMove) {
      this.$nextTick(() => {
        document
          .getElementById("itemScroll")
          .addEventListener("touchmove", this.$tools.noScroll, {
            passive: false,
          });
      });
    } else {
      this.$nextTick(() => {
        document
          .getElementById("itemScroll")
          .removeEventListener("touchmove", this.$tools.noScroll, {
            passive: false,
          });
      });
    }
    this.initScroll();
  },

  methods: {
    initScroll(isTempTop) {
      this.isTempTop = isTempTop;
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.itemScrollList, {
            click: true,
            mouseWheel: true,
            // useTransition: true,
            probeType: 3,
            preventDefault: false,
            bounce_time: 600,
            pullUpLoad: true,
            pullDownRefresh: {
              threshold: 100,
              stop: 56,
            },
            // pullDownRefresh: {
            //   threshold: 50,
            //   stop: 40,
            // },
          });
          // 下拉刷新
          this.scroll.on("pullingDown", this.pullingDownHandler);
          this.scroll.on("pullingUp", this.pullingUpHandler);
          this.scroll.on("refresh", () => {
            if (this.isTempTop) {
              this.scrollTo(0);
              this.isTempTop = false;
              return;
            }
            if (this.isTop) this.scrollTo(0);
            if (this.isBottom) this.scroll.scrollTo(0, this.scroll.maxScrollY);
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    async pullingDownHandler() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullDown();
          if (this.isTop) this.scrollTo(0);
          resolve();
        }, 600);
      });
    },
    async finishPullDown() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullDown();

          resolve();
        }, 600);
        resolve();
      });
      setTimeout(() => {
        this.scroll.refresh();
      }, this.bounce_time);
    },

    async pullingUpHandler() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullUp();

          resolve();
        }, 600);
      });

      this.$emit("pullingUp");
    },

    async finishPullUp() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullUp();

          resolve();
        }, 600);
        resolve();
      });
      setTimeout(() => {
        this.scroll.refresh();
      }, this.bounce_time);
    },
    getScrollAttr(key) {
      return this.scroll[key];
    },
    scrollTo(scrollHeight, time) {
      if (time)
        this.scroll.scrollTo(0, scrollHeight, time, {
          fn: (number) => {
            return number;
          },
        });
      else {
        this.scroll.scrollTo(0, scrollHeight);
      }
    },
  },
};
</script>
<style>
.itemScroll_container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>